import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Backoffice from '../../../core/sysext/CMS/containers/Backoffice';

const InneditTransportPage: FC<PageProps> = props => {
  const {
    location,
    // params: { '*': star },
  } = props;

  return (
    <Backoffice location={location}>
      <p>Calcul du prix d&apos;une livraison de transport</p>
    </Backoffice>
  );
};

export default InneditTransportPage;
